// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(blogPostCategoryId) {
  return Json_Encode$JsonCombinators.object([[
                "blog_post_category_id",
                ID.toJson(blogPostCategoryId)
              ]]);
}

var Req = {
  toJson: toJson
};

function exec(blogPostCategoryId) {
  return Rest.$$fetch("/dashboard/blog_post_categories/" + ID.toString(blogPostCategoryId), {
              NAME: "Delete",
              VAL: undefined
            }, "Empty", undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* ID Not a pure module */
