// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Link from "../../../styleguide/components/Link/Link.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Routes_BlogPost from "../../../routes/common/Routes_BlogPost.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Routes_BlogPostCategory from "../../../routes/common/Routes_BlogPostCategory.res.js";
import * as BlogPostCategoryNavbarScss from "./BlogPostCategoryNavbar.scss";

var css = BlogPostCategoryNavbarScss;

function BlogPostCategoryNavbar(props) {
  var id = props.id;
  var selectedCategory = props.selectedCategory;
  var id$1 = id !== undefined ? Caml_option.valFromOption(id) : 0;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    children: selectedCategory === "NewBlogPostCategory" ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx(Link.make, {
                                          href: Routes_BlogPostCategory.Dashboard.index,
                                          className: css.linkItem,
                                          children: "Categories"
                                        }),
                                    className: css.linkContainer
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx(Link.make, {
                                          href: Routes_BlogPostCategory.Dashboard.$$new,
                                          className: css.linkItem,
                                          children: "New Category"
                                        }),
                                    className: Cx.cx([
                                          css.linkContainer,
                                          css.underlinedLink
                                        ])
                                  })
                            ]
                          }) : (
                        selectedCategory === "BlogPostCategoriesDashboard" ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                children: [
                                  JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx(Link.make, {
                                              href: Routes_BlogPostCategory.Dashboard.index,
                                              className: css.linkItem,
                                              children: "Categories"
                                            }),
                                        className: Cx.cx([
                                              css.linkContainer,
                                              css.underlinedLink
                                            ])
                                      }),
                                  JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx(Link.make, {
                                              href: Routes_BlogPost.Dashboard.index,
                                              className: css.linkItem,
                                              children: "Blog Posts"
                                            }),
                                        className: css.linkContainer
                                      })
                                ]
                              }) : (
                            selectedCategory === "EditBlogPostCategory" ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx(Link.make, {
                                                  href: Routes_BlogPostCategory.Dashboard.index,
                                                  className: css.linkItem,
                                                  children: "Categories"
                                                }),
                                            className: css.linkContainer
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx(Link.make, {
                                                  href: Routes_BlogPostCategory.Dashboard.edit(id$1),
                                                  className: css.linkItem,
                                                  children: "Edit Category"
                                                }),
                                            className: Cx.cx([
                                                  css.linkContainer,
                                                  css.underlinedLink
                                                ])
                                          })
                                    ]
                                  }) : null
                          )
                      ),
                    className: css.barContainer
                  }),
              className: css.barWrapper
            });
}

var make = BlogPostCategoryNavbar;

export {
  css ,
  make ,
}
/* css Not a pure module */
