// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CreateBlogPostCategoryRequest from "./requests/CreateBlogPostCategoryRequest.res.js";
import * as UpdateBlogPostCategoryRequest from "./requests/UpdateBlogPostCategoryRequest.res.js";
import * as DestroyBlogPostCategoryRequest from "./requests/DestroyBlogPostCategoryRequest.res.js";
import * as FeatureBlogPostCategoryRequest from "./requests/FeatureBlogPostCategoryRequest.res.js";
import * as UnfeatureBlogPostCategoryRequest from "./requests/UnfeatureBlogPostCategoryRequest.res.js";
import * as FetchBlogPostCategoriesDashboardRequest from "./requests/FetchBlogPostCategoriesDashboardRequest.res.js";

var dashboard = FetchBlogPostCategoriesDashboardRequest.exec;

var feature = FeatureBlogPostCategoryRequest.exec;

var unfeature = UnfeatureBlogPostCategoryRequest.exec;

var destroy = DestroyBlogPostCategoryRequest.exec;

var create = CreateBlogPostCategoryRequest.exec;

var update = UpdateBlogPostCategoryRequest.exec;

export {
  dashboard ,
  feature ,
  unfeature ,
  destroy ,
  create ,
  update ,
}
/* CreateBlogPostCategoryRequest Not a pure module */
